import Swiper from 'swiper';
import { Pagination, EffectFade, Scrollbar, Mousewheel, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

window.addEventListener('load', () => {
    Swiper.use([Pagination, EffectFade, Scrollbar, Mousewheel, Autoplay]);

    // Initialize all swipers
    initImageSlider();
    initContentSlider();
    initAutoplaySlider();
});

// Initialize image slider
const initImageSlider = () => {
    const galleries = document.querySelectorAll('.swiper-type-scrollbar');
    galleries.forEach((gallery) => {
        new Swiper(gallery, {
            slidesPerView: 1.4,
            spaceBetween: 20,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
            centeredSlides: false,
            grabCursor: true,
            passiveListeners: true,
            mousewheel: {
                enabled: true,
                forceToAxis: true,
            },
            direction: "horizontal",
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
            freeMode: true,
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    slidesOffsetBefore: 40,
                    slidesOffsetAfter: 40,
                    spaceBetween: 20
                },
            },
            on: {
                init: function () {
                    gallery.querySelector('.loader__wrapper').remove();
                    gallery.querySelector('.swiper-wrapper').classList.remove('hidden');
                },
            },
        });
    });
};

// Helper function to initialize content slider
const initContentSlider = () => {
    const sliders = document.querySelectorAll('.swiper-fade');
    sliders.forEach((slider) => {
        new Swiper(slider, {
            slidesPerView: 1,
            effect: "fade", 
            fadeEffect: { crossFade: true },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            on: {
                init: function () {
                    updateSwiperContainerBg(slider, this);
                    document.querySelectorAll('.swiper-pagination-bullet')[0].classList.add('swiper-pagination-bullet-active');
                },
                slideChange: function () {
                    updateSwiperContainerBg(slider, this);
                    animateBullets(this.activeIndex, this.previousIndex, slider);
                },
                paginationUpdate: function () {
                    // Remove default active class from all bullets
                    document.querySelectorAll('.swiper-pagination-bullet').forEach(bullet => bullet.classList.remove('swiper-pagination-bullet-active'));
                },
            }
        });
    });
};

// Helper function to initialize autoplay slider
const initAutoplaySlider = () => {
    const imageBlocks = document.querySelectorAll('.swiper-autoplay');
    imageBlocks.forEach((block) => {
        new Swiper(block, {
            slidesPerView: 1,
            effect: "fade", 
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        });
    });
};

// Update swiper container background based on active slide class
const updateSwiperContainerBg = (slider, swiperInstance) => {
    slider.className = slider.className.replace(/\bbg-\S+/g, '');
    const activeSlide = swiperInstance.slides[swiperInstance.activeIndex];
    const bgClass = Array.from(activeSlide.classList).find(cls => cls.startsWith('bg-'));
    if (bgClass) slider.classList.add(bgClass);
};

// Animate pagination bullets
const animateBullets = (currentIndex, previousIndex, slider) => {
    const bullets = slider.querySelectorAll('.swiper-pagination-bullet');
    const distance = window.innerWidth >= 992 ? 48 : 80;
    const difference = Math.abs(currentIndex - previousIndex);

    bullets.forEach(bullet => bullet.style.transition = 'all 0s');
    bullets[currentIndex].style.transform = `translateX(${currentIndex < previousIndex ? '' : '-'}${distance * difference}px)`;
    bullets[previousIndex].style.transform = `translateX(${currentIndex < previousIndex ? '-' : ''}${distance}px)`;

    if(difference > 1) {
        const startBulletIndex = currentIndex < previousIndex ? currentIndex : previousIndex;
        const endBulletIndex = currentIndex > previousIndex ? currentIndex : previousIndex;
        for (let i = startBulletIndex + 1; i <= endBulletIndex - 1; i++) {
            bullets[i].style.transform = `translateX(${currentIndex < previousIndex ? '-' : ''}${distance}px)`;
        }
    }

    bullets[currentIndex].classList.add('swiper-pagination-bullet-active');

    setTimeout(() => {
        bullets.forEach(bullet => bullet.style.transition = 'all 0.5s');
        bullets.forEach(bullet => bullet.style.transform = 'translateX(0)');
    }, 10);
};