
window.addEventListener('load', () => {

	//  Handles toggling the navigation menu for small screens
	const siteHeader= document.querySelector( '.js-header' );

	if ( ! siteHeader ) {
		return;
	}

	const openMenuButton = siteHeader.querySelector( '.js-open-menu' );
	const closeMenuButton = siteHeader.querySelector( '.js-close-menu' );

	openMenuButton.addEventListener( 'click', (() => {
		siteHeader.classList.add( 'menu-active' );
		document.body.classList.add( 'nav-opened' );
		openMenuButton.setAttribute( 'aria-expanded', 'true' );
	} ));

	closeMenuButton.addEventListener( 'click', (() => {
		siteHeader.classList.remove( 'menu-active' );
		document.body.classList.remove( 'nav-opened' );
		openMenuButton.setAttribute( 'aria-expanded', 'false' );
		// Close all submenus
		siteHeader.querySelectorAll('.menu-item-has-children .sub-menu').forEach((submenu) => {
			submenu.style.height = '0px';
			submenu.classList.remove('active');
		});
	}));


	//Hide header menu on scroll
	let lastScrollTop = 0;
	const headerMain = siteHeader.querySelector('.js-header-main');
	const menu = siteHeader.querySelector('.js-menu');

	window.addEventListener('scroll', () => {
		if (window.innerWidth >= 992) {
			let scrollTop = window.scrollY || document.documentElement.scrollTop;	
				
			if (scrollTop > 200 && scrollTop > lastScrollTop) {
				menu.classList.add('hidden');
			} else {
				menu.classList.remove('hidden');
			}
			lastScrollTop = scrollTop;
		}
	});
	headerMain.addEventListener('mouseover', () => {
		if (window.innerWidth >= 992) {
			menu.classList.remove('hidden');
		}
	});


	// Handle submenus on mobile
	siteHeader.querySelectorAll('.menu-item-has-children > a, .menu-item-has-children .menu-item__title-wrapper').forEach((link) => {
		link.addEventListener('click', (event) => {
			if (window.innerWidth < 992) {
				event.preventDefault();
				
				const submenu = link.nextElementSibling;
	
				// Close all other submenus
				siteHeader.querySelectorAll('.menu-item-has-children .sub-menu').forEach((otherSubmenu) => {
					if (otherSubmenu !== submenu) {
						otherSubmenu.style.height = '0px';
						otherSubmenu.classList.remove('active');
					}
				});
	
				// Toggle the clicked submenu
				submenu.style.height && submenu.style.height !== '0px' ? submenu.style.height = '0px' : submenu.style.height = submenu.scrollHeight + 'px';
				submenu.classList.toggle('active');
			}
		});
	});
	
});

