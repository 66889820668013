
window.addEventListener('load', () => {
    const homeLanding = document.querySelector('.js-home-landing');
    const accessHomeButton = homeLanding?.querySelector('.js-access-home');
    const isHomePage = Boolean(accessHomeButton);

    if (isHomePage) {
        const homeLoaded = getCookie("homeLoaded");

        if (!homeLoaded) {
            disableScroll();
            accessHomeButton.addEventListener('click', handleAccessHomeClick);
            if (window.innerWidth < 992) {
                setTimeout(() => {
                    handleAccessHomeClick();
                }, 2000); 
            } 
        } else {
            hideHomeLanding();
        }
    }

    function disableScroll() {
        document.documentElement.style.overflowY = 'hidden';
        document.body.style.overflowY = 'hidden';
    }

    function enableScroll() {
        document.documentElement.style.overflowY = 'auto';
        document.body.style.overflowY = 'auto';
    }

    function hideHomeLanding() {
        homeLanding.classList.add('hide');
    }

    function handleAccessHomeClick() {
        homeLanding.style.opacity = 0;
        setTimeout(() => {
            hideHomeLanding();
            enableScroll();
            setCookie("homeLoaded", "true");
        }, 500);
    }

    function setCookie(name, value) {
        document.cookie = name + "=" + value + "; path=/; SameSite=Lax;";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var cookieArr = document.cookie.split(';');
        for (var i = 0; i < cookieArr.length; i++) {
            var cookie = cookieArr[i];
            while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
            if (cookie.indexOf(nameEQ) == 0) return cookie.substring(nameEQ.length, cookie.length);
        }
        return null;
    }
});

    