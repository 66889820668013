export default (window.addEventListener('load', () => {
    const pressLoginForm = document.getElementById("loginform");

    if (pressLoginForm) 
        pressLoginForm.addEventListener("submit", function (e) {
            e.preventDefault();

            const formData = new FormData(this);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', `/wp-login.php`, true);
            xhr.setRequestHeader("X-Requested-With", 'XMLHttpRequest');
            xhr.onreadystatechange = function() {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        const responseHTML = document.createElement("div");
                        responseHTML.innerHTML = xhr.responseText;
                        const loginError = responseHTML.querySelector("#login_error");
                        const responseMessage = document.getElementById("response-message");
                        responseMessage.innerHTML = '';
                        responseMessage.style.border = 'none';

                        if (responseMessage && loginError) {
                            responseMessage.style.border = '2px solid #ffb900';
                            responseMessage.append(loginError)
                        } else {
                            window.location.assign(window.location.href + "/images");
                        }
                    }
                }
            }
            xhr.send(formData);
        })

    const logoutButton = document.getElementById("logout-link");
    if (logoutButton) {
        logoutButton.addEventListener('click', e => {
            e.preventDefault();
            window.location = logoutButton.dataset.url;
        })
    }
}))